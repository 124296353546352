.invoice-container {
    float: right;
    width: 70%;
    margin-right: 15%;
    padding: 5px;
    padding-top: 1rem;
    padding-bottom: 5rem;

}

.invoice-cont {
    float: right;
    width: 100%;
    border: 1px solid #dee2e6;
    background-color: #fff;
}

.invoice-logo {
    float: right;
    height: 130px;
    padding: 0;
    margin: 0;
    margin-top: 8px;

}


.text-center {
    text-align: center;
    padding: 0;
    margin: 0;
}

.border-top {
    border-top: 1px solid #dee2e6;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6;
}

.my-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.py-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.py-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.text-primary {
    color: #9081f6;

}

.fw-bold {
    font-weight: 700;
}

.list-unstyled {
    list-style: none;
    padding-left: 0;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
    direction: rtl;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    text-align: center;
    background-color: #fff;
    border-left: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.bg-primary-subtle {
    background-color: rgba(13, 110, 253, 0.1);
}

.invoice-md-flex {
    display: flex;
    flex-direction: row;
}

.justify-content-between {
    justify-content: space-between;
}

.invoice-flex_left {
    padding: 8px;
    padding-right: 8px;
    width: 40%;
    text-align: right;


}

.invoice-flex_right {
    padding: 8px;
    padding-right: 15px;
    width: 40%;
    text-align: right;

}

.mt-5 {
    margin-top: 3rem;
}

.mt-md-0 {
    margin-top: 0;
}

.my-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.fs-5 {
    font-size: 1.25rem;
}

.me-2 {
    margin-right: 0.5rem;
}

.text-muted {
    color: #6c757d;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5);
}

.text-decoration-none {
    text-decoration: none;
}

.invoice-Btn {
    padding: 0.75rem;
    background-color: #9081f6;
    color: #fff;
    text-decoration: none;
    width: 100%;
    margin: auto;
    font-weight: 700;
}

@media screen and (max-width: 785px) {
    .invoice-container {
        width: 100%;
        margin-right: 0%;
        margin: 5px auto;
    }

    .invoice-logo {
        float: right;
        height: 80px;
        padding: 0;
        margin: 0;
        margin-top: 8px;

    }

    .invoice-cont {
        width: 100%;

    }


    .table th,
    .table td {
        padding: .4rem;
        vertical-align: top;
        font-size: 13px;
    }

    .invoice-flex_left {
        padding-right: 5px;
        width: 50%;
        text-align: right;
        font-size: 13px;

    }

    .invoice-flex_right {
        padding-right: 15px;
        width: 50%;
        text-align: right;
        font-size: 13px;
    }



}