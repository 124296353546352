/* FilterTabs.css */
.filter-tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    color: #555;
    margin-right: 10px;
    transition: background-color 0.3s ease;
  }
  
  .tab.active {
    background-color: #6a1b9a;
    color: #fff;
  }
  
  .date-filter {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
  }
  
  .date-filter input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .filter-button {
    padding: 8px;
    background-color: #6a1b9a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }