 
.Desktop-nav-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
  transition: opacity 0.3s ease;

}

.Desktop-nav-arrow:hover {
  opacity: 0.7;
}

.Desktop-left-arrow {
  left: 10px;
}

.Desktop-right-arrow {
  right: 10px;
}

 
.Desktop-product-images {
  display: flex;
  flex-direction: row;  
  width: 40%;
  justify-content: center;
  align-items: flex-start;  
  background-color: #fff;
 

}

 
.Desktop-main-image {
  width: calc(100% - 90px);
  height: auto;
  position: relative;
  margin-bottom: 40px;
 
}

.Desktop-main-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s; 
}

.Desktop-main-image img:hover {
  transform: scale(1.1);
} 

 
.Desktop-thumbnail-list {
  width: 80px;  
  padding: 5px;
  display: flex;
  justify-content: space-between;  
  gap: 10px;
  align-items: center;
  flex-direction: column; 
  margin-top: 20px;
}

.Desktop-thumbnail-image {
  width: 100%;  
  height: 80px;
  object-fit: cover;
  border: 2px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s;
}

.Desktop-thumbnail-image.selected {
  border-color: #888;
}
 