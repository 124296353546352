.notification-settings {
    float: left;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    border: 1px solid #ccc;
}

.category {
    width: 100%;
    margin-bottom: 30px;
    float: left;
}

.category h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    background-color: #f5f5f5;
}

th,
td {
    text-align: left;
    padding: 10px;
}

th {
    font-weight: 600;
    color: #555;
    font-size: 14px;
}

td {
    border-bottom: 1px solid #eaeaea;
    color: #333;
}

tr:last-child td {
    border-bottom: none;
}

input[type="checkbox"] {
    width: 16px;
    height: 16px;
    accent-color: #6a35ff;
    /* Custom checkbox color */
}

.buy-now-btn {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f85555;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buy-now-btn:hover {
    background-color: #e64444;
}

@media (max-width: 600px) {
    .notification-settings {
        padding: 10px;
    }

    table {
        font-size: 14px;
    }

    .buy-now-btn {
        width: 100%;
    }
}