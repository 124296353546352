/* YouTube video container */
.youtube {

    width: 100%;
    float: left;
    padding: 20px;
}

.youtube-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* Aspect ratio: 16:9 */
    position: relative;
    background-color: #000;
    /* Fallback background color */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    /* Soft shadow effect */
}

/* Responsive iframe for YouTube */
.youtube-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
}