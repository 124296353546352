
.chart-container {
  float: left;
    /* background: #f9fbfd; */
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    margin: auto;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
    text-align: center;
  }
  
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }
  
  .date-selector {
    background: #f1f3f5;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    color: #555;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
  }


  /* تصميم متجاوب للشاشات الصغيرة */
@media (max-width: 600px) {
  .chart-container {
      width:100%;
      
    }

 
}