/* الحاوية الرئيسية للمخطط */
.Social-chart {
    position: relative;
    width: 100%;
    float: left;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial, sans-serif';
}

/* عنوان المخطط */
.Social-chart h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333333;
    font-size: 24px;
}

/* كل حملة على حدة */
.Social {
    margin-bottom: 25px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 15px;
}

/* تاريخ الحملة */
.Social p.date {
    font-size: 18px;
    color: #555555;
    margin-bottom: 15px;
}

/* حاوية المؤشرات */
.indicators {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* كل مؤشر على حدة */
.indicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* اسم المؤشر والعدد */
.indicator-info {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100px;
}

.indicator-info1 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.indicator-info .legend-color {
    width: 15px;
    height: 15px;
    border-radius: 3px;
}

.indicator-info span.name {
    font-size: 16px;
    color: #333333;
}

.indicator-info span.count {
    font-size: 14px;
    color: #666666;
}

/* شريط التقدم */
.progress-bar {
    flex: 1;
    height: 5px;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 10px;
}

/* شريط التقدم المخصص */
.progress {
    height: 100%;
    transition: width 0.5s ease;
}

/* ألوان مخصصة لكل مؤشر */
.progress-google {
    background-color: #4285F4;
    /* الأزرق الخاص بجوجل */
}

.progress-twitter {
    background-color: #1DA1F2;
    /* الأزرق الخاص بتويتر */
}

.progress-facebook {
    background-color: #3b5998;
    /* الأزرق الخاص بفيسبوك */
}

.progress-instagram {
    background: linear-gradient(45deg, #f77737, #d62976, #962fbf, #4c68db);
    /* الألوان المتدرجة لإنستغرام */
}

.progress-whatsapp {
    background-color: #25D366;
    /* الأخضر الخاص بواتساب */
}

.progress-youtube {
    background-color: #FF0000;
    /* الأحمر الخاص بيوتيوب */
}

.progress-snapchat {
    background-color: #FFFC00;
    /* الأصفر الخاص بسناب شات */
}

.progress-linkedin {
    background-color: #0077B5;
    /* الأزرق الخاص بلينكدإن */
}

.progress-personal {
    background-color: #ffb700;
}

.progress-Pinterest {
    background-color: #ff3c00;
}



.progress-Other {
    background-color: #2d6c2a;
    /* لون مخصص لأي منصة أخرى */
}

/* تصميم متجاوب للشاشات الصغيرة */
@media (max-width: 600px) {
    .indicator {
        flex-direction: column;
        align-items: flex-start;
    }

    .progress-bar {
        width: 100%;
        margin-left: 0;
        margin-top: 5px;
    }
}