.carousel {
    width: 100%;
    float: left;
}

.carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* height: 100vh; */
    color: #fff;
    text-align: center;
    overflow: hidden;

}

.carousel-image {
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-position: center;
    position: relative;
}

.carousel-text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    /* Add background for better readability */
    padding: 20px;
    border-radius: 10px;
}

.carousel-text-overlay h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.carousel-text-overlay p {
    font-size: 1.2rem;
}

.carousel-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.carousel-button {
    background-color: #ffffff;
    color: #482f2f;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 2px;
    border: 1px solid #ccc;
    transition: background-color 0.3s ease;
}

.carousel-button:hover {
    background-color: #000000;
    color: #fff;
}