.height_image-grid {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 20px;
    background-color: #f9f9f9;
}


.height_image-grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}