 
@media (max-width: 1023px) {
  .content {
    width: 100%!important;
    margin-left: 0!important;
    margin-right: 0!important;
}
}

 .dashboard {
  display: flex;
  transition: all 0.5s ease;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 71px;
  overflow-y: auto; 
  background-color: var( --dash-background-color);
  margin: 0;
  padding: 0px;
 
}

.content {
  flex: 1;
  padding:0px;
  overflow-y: auto;  
  max-height: calc(100vh - 80px);  
  transition: margin 0.5s ease;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
 
}

.rtl .content {
  text-align: right;
 
}

.ltr .content {
  text-align: left;

}

 