.thumbnail {
    margin-top: 5px;
    width: 200px;
    border: 1px solid #e6e5e8;
    background: rgba(46, 38, 61, .025);
    border-radius: 15px 15px 5px 5px;
    float: left;
    margin: 5px;

}

.img {
    width: 100%;
}


.but-remove {
    border-top: 1px solid #e6e5e8;
    width: 100%;
    background-color: #fff;
}

.button-remove {
    width: 100%;
    background-color: #fff;
    color: #6d6777;
    border-top: 1px solid #e6e5e8;
    border-bottom-right-radius: calc(0.375rem - 1px);
    border-bottom-left-radius: calc(0.375rem - 1px);
    display: block;
    text-align: center;
    padding: .375rem 0;
    font-size: .75rem;
    border-radius: 0px 0px 5px 5px;
}

.button-remove:hover {
    background-color: #e6e5e8;
    color: #6d6777;

}