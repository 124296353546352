.Layout-product {
  background-color: #fff;
  width: 100%;
}

.rtl .Layout-product {
  float: right;
}

.ltr .Layout-product {
  float: left;
}

.product-page {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
}

.rtl .product-page {
  text-align: right;
  direction: rtl;
}

.ltr .product-page {
  text-align: left;
  direction: ltr;
}


@media screen and (max-width: 768px) {
  .product-page {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #fff;
  }

}