.div_header{
top: 0;
width: 100% ; 
background-color: var(--dash-secondary-background-color);
color: var(--dash-text-color);
height: 70px;
position: fixed;
z-index: 100;
}

.das_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: none;
    margin-bottom:  0px;
   padding: 0;
  }
  
  .das_header.rtl {
    flex-direction: row-reverse;
  }
  
  .das_header-icons {
    display: flex;
    gap: 15px;
    margin-right: 30px
  }

  @import "~flag-icons/css/flag-icons.min.css";
  .toggle-language {
    font-size: 24px; 
    cursor: pointer;
  }
  
  .das_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px;
  }
  
  .das_header-icons {
    display: flex;
    align-items: center;
  }