.tabs-container {
    width: 100%;
    margin: auto;
    text-align: center;
    
  }
  
  .tabs-header {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tab-item {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    background-color: #f0f0f0;
    
    color: #333;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .tab-item:hover {
    background-color: #ddd;
  }
  
  .tab-item.active {
    background-color: #59636d;
    color: white;
  }
  
  .tabs-content {
    padding: 20px;
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    background-color: none;
  }
  
  .component {
    font-size: 18px;
    font-weight: bold;
    color: #007bff;
  }