/* Toolbar container */
.toolbar {
    display: flex;
    align-items: center;
    gap: 10px;
    /* background-color: #f9f9f9; */
    padding: 10px 20px;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    margin-bottom: 50px;


}


/* Search input styling */
.search-input {
    padding: 8px 12px;
    border: 1px solid #000 !important;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    flex: 1;
    width: 300px;
    margin-top: 0px;
}

.search-input::placeholder {
    color: #aaa;
}

/* Dropdown styling */
.dropdown {
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 3px;
    background-color: white;
    color: #000000;
    font-size: 14px;
    cursor: pointer;
    width: 300px;
    text-align: center;
}

/* Export button styling */
.export-btn {
    padding: 8px 16px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #555;
}

.export-btn span {
    font-size: 12px;
    transform: rotate(90deg);
}

/* Add category button styling */
.add-category-btn {
    padding: 8px 16px;
    border: none;
    background-color: #7b61ff;
    color: white;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
}

.add-category-btn:hover {
    background-color: #5b49d6;
}


@media screen and (max-width: 768px) {

    .toolbar {
        display: block;
        gap: 0px;
        padding: 0px;
    }

    .search-input {
        padding: 0px;
        font-size: 14px;
        outline: none;
        flex: 0;
        width: 100%;
        margin-top: 15px;
    }

    .dropdown {
        margin-top: 15px;
        padding: 10px;
        border-radius: 3px;
        color: #000000;
        font-size: 14px;
        cursor: pointer;
        width: 100%;
        text-align: center;
    }

}