.shipping-zones {
    float: left;
    padding: 20px;
    width: 100%;
    background-color: #fff;
    padding-top: 0;
}

.shipping-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.shipping-header h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}



.zone-card {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
    border: 1px solid #ccc;
    width: 100%;
    float: left;
    margin-bottom: 30px;
}

.zone-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

}

.zone-card-header .avatar {
    width: 40px;
    height: 40px;
    background-color: #e0dfff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    margin-right: 15px;
}

.Flag {
    float: left;
    margin-top: 3px;

}

.zone-header-h3 {
    float: left;
    padding-top: 0px;
    font-size: 18px;
    font-weight: bold;
    margin: 0;

}

.card-header-add {
    padding: 0;
    margin-top: -40px;
}

.zone-header p {
    font-size: 14px;
    color: #777;
    margin: 0;
}

.zone-table {
    float: left;
    width: 100%;
    padding: 5px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
}

table {
    width: 100%;
    border-collapse: collapse;
    /* border: 1px solid #ccc; */
}

table th,
table td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
}

.table-img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.table-icon {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
}

.table-icon:hover {
    background-color: #000;
    color: #fff;

}

.add-rate {
    margin-top: 20px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 10px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    width: 210px;
}

.add-rate:hover {
    background-color: #000;
    color: #fff;
}

.table-detils {

    margin-top: 15px;
    float: right;
    padding: 10px;

}