/* *, :before, :after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  padding: 0;
  margin: 0;
 
}

:root {
    --main-bg-color: #ffffff;
    --main-text-color: #000000;
    --accent-color: #61dafb;
  }
  

  @import"https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap";body {
    font-family: Outfit,sans-serif
}

  body {
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
  }
  
  button {
    background-color: var(--accent-color);
    color: var(--main-text-color);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  button:hover {
    background-color: #21a1f1;
  }




 */

*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  padding: 0;
  margin: 0;

}

:root {
  /* Colors */
  --background-color: #f1f5f9;
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f5f5f5;
  --text-color: #333;
  --main-bg-color: #ffffff;
  --main-text-color: #000000;
  --accent-color: #61dafb;


  /* --primary-color: #3498db;
--secondary-color: #2ecc71;
/* --background-color: #f5f5f5;  

--background-color: #f1f5f9;
--text-color: #333333;
--font-family: 'Arial, sans-serif';
--box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
--border-radius: 5px;
--border-color: #dddddd; */





  /* Typography */
  --font-size-small: 14px;
  --font-size-medium: 16px;
  --font-size-large: 18px;

  /* Spacing */
  --space-xs: 4px;
  --space-sm: 8px;
  --space-md: 16px;
  --space-lg: 32px;

  /* Breakpoints */
  --small-screen: 600px;
  --medium-screen: 990px;
}





@import"https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap";

body {
  font-family: Outfit, sans-serif
}

body {
  /* font-family: Arial, sans-serif; */
  font-family: Outfit, sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

/* Typography */
h1 {
  font-size: calc(var(--font-size-large) * 2);
}

h2 {
  font-size: calc(var(--font-size-large) * 1.5);
}

p {
  font-size: var(--font-size-medium);
}

/* Layouts */
.container {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  padding: var(--space-lg);
}

.Layout {
  background: #fff;
  display: block;
  width: 100%;
}

.rtl .Layout {
  text-align: right;
  float: right;
  direction: rtl;
}

.ltr .Layout {
  text-align: left;
  float: left;
  direction: ltr;
}




@media (min-width: var(--small-screen)) and (max-width: var(--medium-screen)) {
  .container {
    padding: var(--space-md);
  }

}

@media (min-width: var(--medium-screen)) {
  .container {
    padding: var(--space-lg);
  }

}

/* 
const HeaderContainer = styled.header`
background-color: var(--primary-color);
color: white;
padding: var(--space-md);
text-align: center;
font-size: calc(var(--font-size-large) * 1.2);
`; */

/* 
const StyledButton = styled.button`
background-color: var(--secondary-color);
color: white;
padding: var(--space-sm) var(--space-md);
border: none;
border-radius: 4px;
font-size: var(--font-size-medium);
cursor: pointer;
transition: background-color 0.3s;

&:hover {
  background-color: #27ae60;
}
`; */