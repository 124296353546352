 .create-product {
   float: left;
   padding: 20px;
   /* background-color: rgb(255, 255, 255); */
   background-color: #f1f5f9;
   color: #8aa1a3;

 }

 .create-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
 }

 .rtl .create-header {
   direction: rtl;
 }

 .ltr .create-header {
   direction: ltr;

 }


 .add-product-btn {
   background-color: #000000;
   color: white;
   border: none;
   padding: 10px 20px;
   border-radius: 3px;
   cursor: pointer;
 }




 h1 {
   font-size: 2rem;
 }

 .product-form {
   gap: 40px;
 }



 .Product-section-row {
   color: #8aa1a3;
   float: left;
   /* background-color: #f9f9f9; */
   background-color: #ffff;
   padding: 20px;
   border-radius: 5px;
   width: 100%;
   height: auto;
   margin-top: 20px;
   box-shadow: 0 .25rem .625rem rgba(46, 38, 61, .2);
 }


 .Product-section-one {
   background-color: none;
   width: 68%;
   float: left;

 }

 .Product-section-two {
   width: 30%;
   float: left;

 }


 .Product-section {
   background-color: none;
   /* padding: 20px; */
   border-radius: 10px;
   box-sizing: border-box;
   margin-left: 1%;
   float: left;
   margin-bottom: 20px;
 }

 @media (max-width: 768px) {
   .Product-section {
     margin-left: 0%;
   }

   .Product-section-two,
   .Product-section-one {
     background-color: none;
     width: 100%;
     float: left;

   }

 }

 label {
   display: block;
   margin-bottom: 5px;
   font-weight: bold;
   width: 100%;
   float: left;
 }

 input[type="text"],
 input[type="number"],
 textarea,
 select {
   width: 100%;
   padding: 10px;
   margin-bottom: 0px;
   border-radius: 3px;
   border: 1px solid #ddd;
   /* background-color: #f0f0f0; */
   background-color: #fbfafa;
   text-align: center;

 }

 textarea {
   height: 120px;
   text-align: center;
 }



 .error {
   color: red;
   font-size: 12px;
   width: 100%;
   text-align: center;
   padding-top: 0;
   margin-top: 5px;
 }

 .product-attributes {
   display: flex;
   gap: 20px;
   width: 100%;
 }

 .upload-box {
   border: 3px dashed #ddd;
   padding: 30px;
   text-align: center;
   cursor: pointer;
   border-radius: 10px;
   background-color: #fbfafa;
   /* margin-bottom: 20px; */
   height: 170px;
 }

 .upload-box input {
   display: none;
 }

 .upload-box span {
   display: block;
   margin-top: 10px;
   color: #888;
 }

 .image-preview {
   border: 3px dashed #ddd;
   padding: 5px;
   text-align: center;
   cursor: pointer;
   border-radius: 10px;
   /* margin-bottom: 20px; */
   height: 250px;

 }



 .thumbnail {
   margin-top: 5px;
   width: 200px;
   border: 1px solid #e6e5e8;
   background: rgba(46, 38, 61, .025);
   border-radius: 15px 15px 5px 5px;
   float: left;
   margin: 5px;

 }

 .img {
   width: 100%;
 }


 .but-remove {
   border-top: 1px solid #e6e5e8;
   width: 100%;
   background-color: #fff;
 }

 .button-remove {
   width: 100%;
   background-color: #fff;
   color: #6d6777;
   border-top: 1px solid #e6e5e8;
   border-bottom-right-radius: calc(0.375rem - 1px);
   border-bottom-left-radius: calc(0.375rem - 1px);
   display: block;
   text-align: center;
   padding: .375rem 0;
   font-size: .75rem;
   border-radius: 0px 0px 5px 5px;
 }

 .button-remove:hover {
   background-color: #e6e5e8;
   color: #6d6777;

 }


 .tagify__tag {
   white-space: pre-wrap;
   overflow: hidden;
   text-overflow: ellipsis;
   display: inline-block;
   vertical-align: top;
   background-color: #ffffff;
   transition: .8s ease, .1s color;
   border: 1px solid #ccc;
   border-radius: 5px;
   font-size: .8125rem;
   font-weight: 500;
   margin: 5px;
   padding: 5px;
   /* box-shadow: 0 .25rem .625rem rgba(46, 38, 61, .2); */
 }

 .tagify__x {
   margin: 0px;
   padding: 0px;
   color: red;
   /* border-radius: 50px; */
   font-size: 13px;
   margin-left: 5px;
   font-weight: 700;

 }