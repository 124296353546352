 
.card-Modal-Note{
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 100;
   
  }
 
.card-modal-content {
    background-color: #ffffff;
    margin: 15% auto;  
    padding:25px  25px 30px 30px   ;  
    border: 1px solid #888;  
    width: 80%;  
    height: 100px; 
    border-radius: 10px; 
  }
 
.card-Note-TextArea{
 float: right;
 width: 80%;
 }

.Note-textarea{
    padding-top: 10px;
    float: right;
    width: 100%;
    text-align: center;
 }

.note-card-but-Save{
 float: right;
 width: 20%;
}
  

.card-but-Save{
    margin-top: 0px;
    float: right;
    width: 100%;
    margin-right:5%;
    border: 1px solid #dee2e6;
    padding:17px;
    font-size: 15px;
    font-weight: 700;
    background-color: black;
    color: #ffffff; }
 
.card-but-Save:hover { background-color: rgb(36, 34, 34); }
   
 

 @media screen and (max-width: 768px) {
    .card-modal-content {
        background-color: #ffffff;
        margin: 15% auto;  
        padding:0 ;  
        border: 0px solid #888;  
        width: 90%;  
        height: 100px; 
        border-radius: 10px; 
        margin-top: 200px;
      }
      .note-card-but-Save{
        float: right;
        width: 100%;
        margin: 0;
       }
       .card-but-Save{
       margin-top: 5px;
        margin-right:0%;
       }
       .Note-textarea{
        padding:10px;
        float: right;
        width: 100%;
        text-align: center;
        height: 150px;
     }


       .card-Note-TextArea{
        float: right;
        width: 100%;
        }
       
  }