.customer-details-row {
    float: left;
    width: 100%;
    font-size: 15px;
    margin-bottom: 5px;
}


.UserIcon {
    float: left;
    color: #433c50;
    font-size: 50px;
    padding: 5px;

}

.avatar {
    float: left;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50px;
}

.UserName {
    float: left;
    color: #433c50;
    font-size: 20px;
    margin-top: 8px;
    margin-left: 5px;
}

.CartIcon {
    float: left;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 50px;
    background-color: aquamarine;
    font-size: 18px;
    margin-right: 10px;
}