.type-device-container {
    margin: auto;
    text-align: center;
}

.device-selector {
    margin-bottom: 0px;

}

.device-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

}

.device {
    position: relative;
    background: #fff;
    border: 2px solid #000;
    border-radius: 20px;
    overflow: hidden;
}

.mobile {
    /* width: 300px; */
    width: 600px;
    height: 600px;
    border-radius: 30px;
}

.tablet {
    width: 800px;
    height: 500px;
    border-radius: 20px;
}

.desktop {
    width: 1200px;
    height: 700px;
    border-radius: 15px;
}

.device::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: #000;
    border-radius: 10px;
}

.mobile::before {
    width: 40px;
    height: 5px;
}

.tablet::before {
    width: 60px;
    height: 5px;
}

.desktop::before {
    width: 80px;
    height: 10px;
}

.screen {
    height: 100%;
    overflow-y: scroll;
    /* تفعيل التمرير العمودي */
    padding: 10px;
    /* بعض الحشوة */
}

.container {
    height: 100px;
    background: #e0e0e0;
    margin-bottom: 0px;
    /* المساحة بين الكونتينرات */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    /* توسيط المحتوى داخل الكونتينر */
    align-items: center;
    font-size: 20px;
    background-color: antiquewhite;
}