.add-section-container {
    margin-bottom: 20px;
    padding: 20px;
    border: 2px dashed #000000; /* A green dashed border */
    border-radius: 8px;
    background-color: #f9f9f9; /* Light background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: 100%; 
    /* width: calc(100% - 40px);   */
    position: relative;
}

.add-section-header {
    /* font-size: 24px;   */
    margin-bottom: 10px;
    color: #333; /* Darker text for contrast */
}

.add-section-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #000; /* Green button */
    color: white; /* White text color */
    border: none;
    border-radius: 4px;
    padding: 8px 12px; /* Padding for button */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transition effect */
}

.add-section-button:hover {
    background-color: #1f201f; /* Darker green on hover */
}

.add-section-form {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
}

.add-section-form label {
    margin-bottom: 5px; /* Spacing below the label */
    font-weight: bold; /* Bold label for better readability */
}

.add-section-form input {
    padding: 10px;
    margin-bottom: 15px; /* Spacing below the input */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size for input */
}

.add-section-form input:focus {
    border-color: #000000; /* Change border color on focus */
    outline: none; /* Remove default outline */
}

.submit-button {
    background-color: #000000; /* Blue button */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px; /* Padding for submit button */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transition effect */
}

.submit-button:hover {
    background-color: #000000; /* Darker blue on hover */
}

.success-message {
    color: green;
    margin-top: 10px; /* Space above success message */
}

.error-message {
    color: red;
    margin-top: 10px; /* Space above error message */
}