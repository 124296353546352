.tabs-container {
    margin-top: 15px;
    width: 100%;
    float: left;
    font-family: Arial, sans-serif;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    /* overflow: hidden; */
    /* height: 90vh; */
    box-shadow: 0 .25rem .625rem rgba(46, 38, 61, .2);
}

.Tabs-header {
    position: relative;
    float: left;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    /* box-shadow: 0 .25rem .625rem rgba(46, 38, 61, .2); */
    border-bottom: 1px solid #ccc;
}


.tab-button {
    float: left;
    padding: 8px 15px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    color: #7c7878;
    /* background: #dddddd29; */
    background: #fff;
    transition: all 0.3s ease;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-weight: bold;
    margin: 10px 6px;
    box-shadow: 0 .25rem .625rem rgba(46, 38, 61, .2);
}


.tab-button:hover {
    background-color: #f1f1f1;
}

.tab-button.active {
    background-color: #000000;
    color: #fff;
    font-weight: bold;
    border-bottom: 3px solid #3d4044;
    box-shadow: 0 .25rem .625rem rgba(46, 38, 61, .2);
}


.tabs-content {
    float: left;
    padding: 20px;
    background-color: #fff;
    font-size: 14px;
    color: #333;
    line-height: 1.6;
    width: 100%;
    height: auto;
    margin-bottom: 80px;
}