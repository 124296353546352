.faq {
    width: 100%;
    float: left;
    padding: 10px;
    padding: 50px;
}



.faq-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.rtl .faq-container {
    text-align: right;
    direction: rtl;
}

.ltr .faq-container {
    text-align: left;
    direction: ltr;
}


.faq-section {
    width: 50%;
}

.contact-section {
    margin-top: 30px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 45%;
    padding: 50px;
    background: linear-gradient(to right, #d6e5ff, #fde5df);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

}

.Faq-div-title {
    float: left;
    width: 100%;

    text-align: center;
}

.rtl .Faq-div-title {
    text-align: right;

}

.ltr.Faq-div-title {
    text-align: left;

}


.faq-title {
    float: left;
    width: 100%;
    font-size: 24px;

}



.faq-item {
    margin-bottom: 15px;

}

.faq-question {
    display: block;
    width: 100%;
    text-align: left;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.rtl .faq-question {
    text-align: right;
    direction: rtl;
}

.ltr .faq-question {
    text-align: left;
    direction: ltr;
}


.faq-question:hover {
    background-color: #f1f1f1;
}

.faq-question.active {
    background-color: #000000;
    color: #ffffff;
}

.faq-answer {
    padding: 10px 15px;
    background-color: #ffffff;
    border-left: 2px solid #000000;
    font-size: 18px;
    color: #666;
    margin-top: 5px;
}

.rtl .faq-answer {
    text-align: right;
    direction: rtl;
}

.ltr .faq-answer {
    text-align: left;
    direction: ltr;
}

.contact-section h3 {
    width: 100%;
    float: left;
    font-size: 20px;
    color: #333;
    padding: 5px;
    padding-bottom: 10px;

}

.contact-section p {
    width: 100%;
    float: left;
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
    padding: 5px;
}

.contact-button {
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: 50px;
    padding: 15px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid #ccc;
}

.contact-button:hover {
    background-color: #000000;
    color: #ffffff;
}

@media (max-width: 768px) {
    .faq {
        width: 100%;
        float: left;
        padding: 30px;
    }


    .faq-container {
        width: 100%;
        display: inline;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .faq-section {
        width: 100%;
    }

    .contact-section {
        margin-top: 30px;
        padding: 20px;
        border-radius: 5px;
        text-align: center;
        width: 100%;
        padding: 50px;
        background: linear-gradient(to right, #d6e5ff, #fde5df);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    }


}