 .main-wrapper {
     float: left;
     width: 100%;
     margin-top: 150px;

     @media (max-width: 768px) {
         margin-top: 150px;

         width: 90%;
         margin-left: 5%;
         height: auto;

         margin-bottom: 50px;

     }
 }

 .Row1 {
     border-radius: 5px;
     border: 1px solid #58a58f;
     background-color: #fdffff;
     /* border: 1px solid rgba(48, 146, 85, 0.2); */
     padding: 10px;
     box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
     width: 500px;
     margin: auto;

     @media (max-width: 768px) {
         width: 100%;
         border: 0px solid #58a58f;
         box-shadow: none;

     }
 }

 .Row2 {

     -webkit-box-flex: 0;
     -webkit-flex: 0 0 auto;
     -ms-flex: 0 0 auto;
     flex: 0 0 auto;
     width: 40%;
     float: left;
     z-index: 0;

     @media (max-width: 768px) {
         width: 100%;

     }
 }

 .TitlE {
     margin-top: 0;
     text-align: center;
     font-size: 20px;
     font-weight: 300;
     margin-bottom: 0;
     line-height: 1;
     padding-bottom: 10px;

     @media (max-width: 768px) {
         text-align: center;
     }
 }

 .login-form {
     margin-top: 20px;
     width: 100%;
     padding: 10px;

     @media (max-width: 768px) {
         width: 100%;
         margin-left: 0px;
     }
 }

 .register-login-form {
     margin-top: 20px;
     width: 90%;
     margin-left: 8%;

     @media (max-width: 768px) {
         width: 100%;
         margin-left: 0px;
     }
 }

 .single-form {
     margin-top: 15px;
 }



 .single-form input:not([type="checkbox"]):not([type="radio"]),
 .single-form textarea {
     width: 100%;
     line-height: 2rem;
     padding: 0 20px;
     font-size: 12px;
     color: #52565b;
     -webkit-transition: all 0.3s ease 0s;
     -o-transition: all 0.3s ease 0s;
     transition: all 0.3s ease 0s;
     border: 1px solid rgba(48, 146, 85, 0.2);
     border-radius: 5px;
     background-color: #fff;
 }


 .Btn {
     margin-top: 15px;
     width: 100%;
     display: inline-block;
     font-weight: 300;
     line-height: 2rem;
     text-align: center;
     text-decoration: none;
     vertical-align: middle;
     cursor: pointer;
     background-color: transparent;
     border: 0 solid transparent;
     padding: 1px;
     font-size: 1rem;
     border-radius: 5px;
 }


 .Btn-primary {
     color: #fff;
     background-color: #58a58f;

 }


 .Btn-secondary {
     color: #58a58f;
     background-color: #e7f8ee;
     border-color: #e7f8ee;
     border: 1px solid rgba(48, 146, 85, 0.2);
 }



 .register-login-images {
     background-color: #e7f8ee;
     /* padding: 45px 40px 0; */
     text-align: center;
     border-radius: 10px;
     overflow: hidden;
     position: relative;

     margin-top: 28px;
     width: 100%;
     height: 395px;
     margin-left: 20px;
     border: 1px solid #e7f8ee;

     @media (max-width: 768px) {
         width: 100%;
         margin-left: 0px;
     }
 }

 .images {

     height: 100%;

 }

 .forgot-password-link {
     margin-top: 10px;
     text-align: center;
 }

 .link {
     color: #000;
     /* Blue color, you can adjust this */
     text-decoration: none;
     font-size: 13px;
     /* font-weight: bold; */
 }

 .link:hover {
     text-decoration: underline;
     color: #58a58f;
 }