.Mobile-product-details {
  float: left;
  padding: 0 20px;
  width: 100%;

}

.Mobile-product-titel {
  font-size: 25px;
  line-height: 33.6px;
  color: #000;
  text-align: center;
  float: left;
  width: 100%;
}

.Mobile-best {
  margin-top: 15px;
  width: 100%;
  float: left;
}


.Mobile-best-seller {
  float: left;
  margin-top: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 11px;
  border: 2px solid #000;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 700;
  line-height: 29px;
}

.Mobile-product-status {
  margin-top: 5px;
  float: left;

}

.Mobile-product-status-content {
  float: left;
  margin-left: 5px;
  font-size: 20px;
  color: #9081f6;
  animation: tf-ani-flash 2s infinite;
}

@keyframes tf-ani-flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Mobile-selling-fast {
  margin-top: 5px;
  float: left;
}

.Mobile-price-section {
  padding-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
 

}

.Mobile-price {
  display: block;
  font-size: 24px;
  color: #9081f6;
  font-weight: 700;
 
}

.Mobile-original-price {
  text-decoration: line-through;
  color: grey;
  display: block;
 
}

.Mobile-discount {
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 4px 8px;
  border-radius: 50px;
  display: block;

}
.Mobile-price-section,
.Mobile-price,
.Mobile-original-price,
.Mobile-discount {
  display: block !important;
}

@media (max-width: 768px) {
  .Mobile-price-section {
    display: flex !important;
  }

  .Mobile-price,
  .Mobile-original-price,
  .Mobile-discount {
    display: block !important;
  }
}





.Mobile-viewers {
  margin-top: 5px;
}

.Mobile-description {
  padding: 5px;
  margin-top: 5px;
  line-height: 30px;

}

.Mobile-countdown-timer {
  text-align: center;
  padding: 15px;
  margin: 10px 0;
  border-radius: 4px;
  border: 2px solid red;
}

.Mobile-countdown-timer-p {
  color: #000;
  padding-bottom: 5px;
}

.Mobile-product-status-Alarm {
  margin-left: 0px;
  font-size: 20px;
  animation: tf-ani-flash 2s infinite;
}

.Mobile-countdown-timer-time {
  padding-top: 15px;
  color: red;
  font-weight: 700;

}



.Mobile-color-selector,
.Mobile-size-selector {
  margin-top: 10px;
}

.Mobile-color-option {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #ccc;
}

.Mobile-color-option.beige {
  background-color: beige;
}

.Mobile-color-option.black {
  background-color: black;
}

.Mobile-color-option.blue {
  background-color: blue;
}

.Mobile-size-option {
  padding: 8px 12px;
  margin-right: 5px;
  border: 1px solid #ccc;
}

.Mobile-size-option.selected {
  background-color: black;
  color: white;
}

.Mobile-quantity-selector {
  width: 100%;
  margin: 10px auto;
  padding: 0px;
  border-radius: 5px;
  
 
}

 
.Mobile-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:  0px;
  width: 100%;
}

.Mobile-quantity-button,
.Mobile-quantity-value {
  display: inline-block;  
  text-align: center;  
}

.Mobile-quantity-but{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  background-color: #9081f6;
  border: 2px solid #9081f6;
  border-radius: 5px;
  padding: 1px;
}
 

.Mobile-quantity-button {
  background-color: #9081f6;
  border: 2px solid #9081f6;
  color: white;
  border: none;
  width: 100px;
  height: 40px;
  font-size: 30px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
}

.Mobile-quantity-button:hover {
  background-color: #23272b;
}

.Mobile-quantity-value {
  background-color: #9081f6;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin: 0  5px;
  font-size: 20px;
  font-weight: bold;
  color: #fff!important;
  display: block;
  width: 50px;
 
}
 

.Mobile-notes-section{

width: 100%;
float: left;

}

.Mobile-button {
  float: right;
  width: 100%;
  margin-top: 15px;

}

.Sell-Row-name {
  float: right;
  width: 49%;
}

.Sell-Row-price {
  float: left;
  width: 49%;
}


.Sell-button-pay {
  border: 2px solid #9081f6;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  padding: 11px 0;
  text-align: center;
  width: 100%;
  background-color: #9081f6;
  color: #fff;
}

.Sell-button-btn {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  padding: 11px 0;
  text-align: center;
  width: 100%;

}

.Mobile-favorite-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;


}

/*  

.Mobile-notes-section {
  margin-top: 15px;
}

.note-input {
  width: 100%;
  height: 80px;  
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;  
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: none;  
  background-color: #f9f9f9;  
}

.note-input::placeholder {
  color: #aaa;  
} */
 
.Mobile-quantity-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.Mobile-quantity-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  float: left;
  width: 100%;
 
}

.Mobile-quantity-button {
  font-size: 1.5rem;
  padding: 5px 10px;
  cursor: pointer;
}


.Mobile-note-textarea{
  width: 100%;
  height: 80px;
  margin-top: 10px;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  line-height: 1.5;

}

.note-textarea:focus {
  border-color: black;
  outline: none;
}