.coupon-btn {
    background-color: #9081f6;
    color: #fff; /* White text */
    border: none; /* Remove border */
    border-radius: 4px;
    padding: 12px 20px; /* Increase padding for bigger button */
    cursor: pointer; /* Add pointer cursor */
    text-align: center;
    margin-top: 14px;
    flex-shrink: 0; /* Prevent button from shrinking */
    font-size: 16px; /* Increase font size for button */
  }
  
  .coupon-input-group {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    width: 100%;
  }
  
  .Form-control {
    /* flex: 1; */
    border: 1px solid #dee2e6 !important; /* Ensure the border style is applied */
    border-radius: 4px !important;
    padding: 12px !important; /* Increase padding for larger input */
    font-size: 16px !important; /* Make font size larger */
    text-align: center;
    margin-left: 10px; /* Add some space between input and button */
  }
  
  .section-card {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures space between button and input */
    gap: 10px; /* Add space between button and input */
    width: 100%;
  }
  
  .successMessage, .errorMessage {
    margin-top: 10px;
    font-size: 14px;
    color: #ff0000; /* Color for error message */
  }
  
  .successMessage {
    color: green;
  }
 
  /* تخصيص /* تخصيص للـ input ليأخذ شكله الطبيعي ثم يشغل باقي المساحة */
.custom-input {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  text-align: center;
  margin-left: 10px;
  width: auto; /* ليأخذ الشكل الطبيعي أولًا */
  flex-grow: 1; /* يشغل المساحة المتبقية */
  min-width: 0; /* لتجنب تمدد الـ input إذا كانت الحاوية صغيرة جدًا */
}
/* تخصيص للزر */
.coupon-btn {
    background-color: #9081f6;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 18px;
    cursor: pointer;
    text-align: center;
    margin-top:  0px;
    flex-shrink: 0;
    font-size: 15px;

  }
  
  /* تخصيص للـ input ليأخذ شكله الطبيعي ثم يشغل باقي المساحة */
  .custom-input {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 12px;
    font-size: 16px;
    text-align: center;
    margin-left: 10px;
    width: 100%; /* اجعل الـ input يشغل المساحة المتاحة */
    min-width: 0; /* لتجنب تمدد الـ input إذا كانت الحاوية صغيرة جدًا */
  }
  
  /* تخصيص للمجموعة */
  .coupon-input-group {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .section-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }
  
  .successMessage, .errorMessage {
    margin-top: 10px;
    font-size: 14px;
    color: #ff0000; /* Color for error message */
  }
  
  .successMessage {
    color: green;
  }
  
  .div-btn {
    flex: 0 0 25%; /* تخصيص نسبة العرض لتكون مرنة */
  }
  
  .div-input {
    flex: 1; /* يشغل المساحة المتبقية */
  }