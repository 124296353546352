.Active_Container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Allows items to wrap to the next line on smaller screens */
}

.active-users-card {
    display: flex;
    align-items: center;
    /* background-color: rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */


    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


    padding: 15px;
    margin: 10px;
    width: calc(25% - 20px);  
    transition: transform 0.2s;
}

.active-users-card:hover {
    transform: scale(1.05);
}

.active-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.active-arrow-icon {
    color: #007bff;
    font-size: 24px;
}

.active-text-container {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.active-text-container-text {
    width: 100%;
    text-align: left;
}

.active-text-container-itme {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.active-text-container-itme_p {
    font-weight: bold;
    font-size: 1.5rem;
    color: #333;
}

.active-percentage {
    color: green;
    font-weight: bold;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .active-users-card {
        width: 100%; /* Full width on mobile */
        margin: 10px 0; /* Adjust margins for full-width layout */
    }
}