 .product-card-col {
     position: relative;
     float: right;
     min-height: 1px;
     padding-right: 15px;
     padding-left: 15px;
     margin-bottom: 10px;

 }

 .product-card {
     width: 50px;
     float: right;
 }

 .card-img-top {
     width: 100%;
     border-radius: 3px;
 }



 .card-badge {
     position: absolute;
     width: 20px;
     height: 20px;
     top: -10px;
     left: 0px;
     z-index: 2;
     text-align: center;
     border-radius: 100%;
     background-color: #4e4d59;
     color: white;
     font-size: 0.8rem;
     padding-top: 2px;
 }

 .card-badge:hover {
     background-color: #9081f6;
 }