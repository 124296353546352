.profile-tabs {
    width: 100%;
    /* margin: 20px auto; */
}

.profile-tabs {
    float: left;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    width: 100%;

}

.profile-tabs button {
    flex: 1;
    /* padding: 10px; */
    padding: 3px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 1em;
    color: #666;
}

.profile-tabs .profile-active-tab {
    color: #ffffff;
    /* background-color: #9b5de5; */
    font-weight: bold;
    box-shadow: 0 .125rem .25rem 0 rgba(46, 38, 61, .16);
    border-radius: 0.375rem;
}

.profile-content {
    float: left;
    padding: 0px;
    gap: 20px;
    width: 100%;
    padding-bottom: 40px;
}

.profile-about,
.profile-activity-timeline {
    flex: 1;
}

.profile-about h3,
.profile-activity-timeline h3 {
    margin-bottom: 10px;
    color: #333;
}