.Pricing-sub {
  width: 100%;
  margin-top: 0px;
  padding: 20px;
  border-radius: 0px;
  float: left;

}



.Pricing-sub-title {
  color: #484848;
  padding-right: 25px;
  margin-bottom: 10px;
  text-align: right;
  font-weight: bold;
  font-size: 15px;
  line-height: 30px;
  cursor: text;
  width: 100%;
  display: flex; /* Use flexbox to align items side by side */
  flex-wrap: nowrap; /* Prevent items from wrapping onto new lines */
  justify-content: space-between; /* Distribute space evenly between elements */
  align-items: center; /* Vertically center the items */
 
  background: #f7f7f8;
}

.Pricing-Summary {
  width: 100%;
  border-radius: 8px;
  margin-right: 0%;
  background: #f7f7f8;
  padding: 16px;
  float: right;
  display: block;
  border: 1px solid #ccc;


}

.totals-item {
  margin-bottom: 10px;
  display: block;
}



.pricing-title {
  font-size: 16px;
  color: #333;
  display: block;

}

.pricing-amount {
  font-size: 16px;
  color: #555;
  display: block;

}

.total-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.total-amount {
  font-size: 18px;
  font-weight: bold;
  color: #555;
}

.text-right {
  float: right;
  text-align: left;
  margin-bottom: 15px;

}

.col {
  float: right;
  width: 60%;
  /* Adjust width as needed */
  text-align: right;
  margin-bottom: 15px;


}



@media screen and (max-width: 1200px) {

  .pricing-title {
    font-size: 12px;
    color: #333;
    display: block;

  }




  .text-right {
    float: right;
    text-align: left;
    width: 45%;
    /* Adjust width as needed */
    margin-bottom: 15px;
    display: block;

  }

  .col {
    float: right;
    width: 45%;
    /* Adjust width as needed */
  
    text-align: right;
    margin-bottom: 15px;
    font-size: 10px;
    display: block;

  }

}