/* CustomerData.css */

.customer-data-container {
    float: left;
    /* background: #f9fbfd; */
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    margin: auto;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
    text-align: center;
  }
  
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }
  
  .date-selector {
    background: #f1f3f5;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    color: #555;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .data-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  
  .data-card {
    background: #fff;
    padding: 20px 50px  ;
    border: 1px solid #e0e4e7;
    border-radius: 8px;
    text-align: center;
  }
  
  .data-value {
    font-size: 24px;
    font-weight: 700;
    color: #333;
  }
  
  .data-label {
    font-size: 12px;
    font-weight: 500;
    color: #9a9fa4;
    margin-top: 4px;
    text-transform: uppercase;
    padding: 7px;
  }


  /* تصميم متجاوب للشاشات الصغيرة */
  @media (max-width: 600px) {
    .customer-data-container  {
        width:100%;
        
      }
  
   
  }