/* Main container   */
.Main-container {
    width: 100%;
    float: left;
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    margin-top: 50px;
}

h1 {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}





/* Navigation buttons */
.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    padding-bottom: 15px;
}

.Main-button {
    background-color: #000000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.Main-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.Main-button:hover:not(:disabled) {
    background-color: #000000;
}