 .SuccessMessage {
     float: right;
     width: 70%;
     margin-right: 15%;
     padding: 15px;
     margin-top: 5rem;
     margin-bottom: .1rem;
     background-color: #b4a9f53b;
     color: #9081f6;
     border: 1px solid #9081f6;
     border-radius: 8px;

 }

 .SuccessMessage-text {
     text-align: right;
     padding: 0;
     margin: 0;
     float: right;
     width: 100%;
 }

 .SuccessMessage-icon {
     float: right;
     padding: 5px;
     margin-left: 11px;
     display: block;
 }

 @media screen and (max-width: 785px) {
     .SuccessMessage {
         width: 98%;
         margin-right: 1%;
         /* margin: 15px auto; */

     }

     .SuccessMessage-text {
         text-align: center;
         padding: 0;
         margin: 0;
         float: right;
         width: 100%;
     }


 }