/* src/CustomerRatings.css */

.customer-ratings {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    float: left;
    width: 100%;

}

.customer-ratings h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.rating-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.rating {
    font-size: 2rem;
    font-weight: bold;
    margin-right: 10px;
}

.stars {
    display: flex;
}

.points {
    font-size: 0.9rem;
    color: gray;
    margin-left: 10px;
}