.product-grid {
    float: left;
    width: 100%;
    padding: 20px;

}

.section-title {
    text-align: center;
    font-size: 2rem;
    margin: 20px 0 10px;
}

.section-subtitle {
    text-align: center;
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}


.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
}

.product-cards {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
}


.image-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    background-color: #f0f0f0;
}

.product-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.product-details {
    width: 100%;
    margin-top: 10px;
}

.product-name {
    font-size: 1rem;
    margin: 5px 0;
    color: #333;
}

.product-brand {
    font-size: 0.9rem;
    color: #999;
    margin-bottom: 10px;
}

.product-save {
    font-size: 1rem;
    color: #aaa;
    font-weight: 500;

}


.price-stock-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.price-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.current-price {
    font-size: 1rem;
    font-weight: bold;
    color: #000;
}

.original-price {
    font-size: 1rem;
    color: #aaa;
    text-decoration: line-through;
}

.availability {
    font-size: 0.9rem;
    font-family: 500;
}

.in-stock {
    color: hsl(0deg 0% 24.71%);
}

.out-stock {
    color: red;
}


.colors-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.color-options {
    display: flex;
    gap: 5px;
}

.color-circle {
    width: 15px;
    height: 15px;
    border-radius: 20%;
    border: 1px solid #ccc;
}


.quick-add-button {
    background-color: hsl(0deg 0% 95.69%);
    color: hsl(0deg 0% 24.71%);
    border: none;
    padding: 10px 25px;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    border: 1px solid #ccc;
}

.quick-add-button:hover {
    background-color: #333;
    color: #fff;
}


@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr;
    }
}