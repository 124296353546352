.features-container {
    padding: 2rem;
}

.features-container h3 {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.feature-card .icon {
    width: 60px;
    height: 60px;
    margin-bottom: 1rem;
    background: rgba(52, 152, 219, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature-card .icon img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.feature-card h4 {
    font-size: 1.1rem;
    color: #2c3e50;
    margin-bottom: 0.5rem;
}

.feature-card p {
    font-size: 0.9rem;
    color: #7f8c8d;
    line-height: 1.5;
}